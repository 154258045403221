import React, { useRef, useState, useEffect } from "react";
import { navigate } from "gatsby";
import locationsByState from "../../helpers/locationsByState";

import * as styleSheet from "./index.module.scss"


const LocationSelector = ({ locations }) => {
	const selectRef = useRef();
	const [error, setError] = useState(false);
	const [stateLocations, setStateLocations] = useState(null);

	useEffect(() => {
		const uniqueStatesAndSubLocations = locationsByState(locations);
		setStateLocations(uniqueStatesAndSubLocations);
	}, [locations]);

	const goToLocation = () => {
		setError(false);
		if( selectRef.current && selectRef.current.options.selectedIndex ){
			if( selectRef.current.options[selectRef.current.options.selectedIndex].value ){
				navigate(selectRef.current.options[selectRef.current.options.selectedIndex].value);
			} else {
				setError(true);
			}
		} else {
			setError(true);
		}
	}

  return (
		<div className={styleSheet.LocationSelector}>
	
			<span className={styleSheet.heading}>This service is offered at these locations:</span>
			<div className={error ? styleSheet.selectcontainererror : styleSheet.selectcontainer}>
				<select ref={selectRef} aria-label="This service is offered at these locations:">
					<option value={false}>Choose a location</option>
					{ locations.map( (location, index) => {
						return <option key={location.id} value={location.link} aria-label={`Select ${location.title}`}  dangerouslySetInnerHTML={{__html: location.ACFLocation.menuTitle ? `${location.ACFLocation.menuTitle}` : `${location.birdeyeLocation.location.city}, ${location.birdeyeLocation.location.state}`}}></option>
					})}
				</select>	

				<select ref={selectRef}aria-label="This service is offered at these locations:">
					<option value={false}>Choose a location</option>
					{stateLocations &&
						stateLocations.map((state, index) => (
							<optgroup label={state.state} key={index}>
								{state.subLocations.map((location) => (
									<option
										key={location.id}
										value={location.link}
										aria-label={`Select ${location.title}`}
										dangerouslySetInnerHTML={{
											__html: location.ACFLocation.menuTitle
												? `${location.ACFLocation.menuTitle}`
												: `${location.birdeyeLocation.location.city}, ${location.birdeyeLocation.location.state}`,
										}}
									></option>
								))}
							</optgroup>
						))}
				</select>	
			</div>
			<button className={styleSheet.go} aria-label="Go to location" onClick={(event)=>{ goToLocation();  }}>Go</button>

		</div>
	);
}

export default LocationSelector;
