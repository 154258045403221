const locationsByState = (locations) => {
	const uniqueStatesAndSubLocations = [];
	const stateMap = {};

	locations.forEach(location => {
		const state = location.birdeyeLocation.location.state;

		if (!location.ACFLocation || !location.birdeyeLocation) {
			return;
		}

		if (!stateMap[state]) {
			stateMap[state] = true;
			uniqueStatesAndSubLocations.push({ state, subLocations: [] });
		}

		const stateIndex = uniqueStatesAndSubLocations.findIndex(item => item.state === state);
		uniqueStatesAndSubLocations[stateIndex].subLocations.push(location);
	});

	uniqueStatesAndSubLocations.sort(function (a, b) {
		var keyA = a.state,
			keyB = b.state;
		if (keyA < keyB) return -1;
		if (keyA > keyB) return 1;
		return 0;
	});

	return uniqueStatesAndSubLocations;
};

export default locationsByState;
